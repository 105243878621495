import React from "react"
import { Container, Row, Col, Card } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

import './threeboxes.scss';

const ThreeBoxes = () => (
  <section className="threeBoxes">
    <Container>
      <Row>
        <Col md={4}>
          <Card>
            <StaticImage 
                src="../../images/icon-people-management.png" 
                alt="People Management" 
                title="People Management"
                width="141"
                height="126"  
                className="icon" 
                loading="lazy" 
              />
            <h2>People<br />Management</h2>
            <ul>
              <li>
                Identity &amp; Administration
              </li>
              <li>
                HR &amp; CRM
              </li>
              <li>
                Digital Timekeeping
              </li>
              <li>
                Support &amp; Tickets
              </li>
              <li>
                Training, QA, Help
              </li>
              <li>
                Security &amp; Controls
              </li>
            </ul>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <StaticImage 
                src="../../images/icon-project-management.png" 
                alt="Project Management" 
                title="Project Management"
                width="88"
                height="118" 
                className="icon" 
                loading="lazy" 
              />
            <h2>Project<br />Management</h2>
            <ul>
              <li>
                Estimates &amp; Contracts
              </li>
              <li>
                Tasks &amp; Milestones
              </li>
              <li>
                File Repository &amp; Share
              </li>
              <li>
                Discussions &amp; Tasks
              </li>
              <li>
                Status Reports
              </li>
              <li>
                Marketing &amp; PR Campaigns
              </li>
            </ul>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <StaticImage 
                src="../../images/icon-profitability-management.png" 
                alt="Profitability Management" 
                title="Profitability Management"
                width="114"
                height="125" 
                className="icon" 
                loading="lazy" 
              />
            <h2>Profitability<br />Management</h2>
            <ul>
              <li>
                Estimates vs. Actuals
              </li>
              <li>
                Retainer Management
              </li>
              <li>
                Analytics &amp; Reporting
              </li>
              <li>
                Gantt Charts
              </li>
              <li>
                Status Monitoring
              </li>
              <li>
                Custom Tools
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    </Container>
  </section>
)

export default ThreeBoxes
