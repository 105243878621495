import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

// import { StaticImage } from "gatsby-plugin-image"

import './ctafullwidth.scss';

const CTAFullWidth = () => (
  <section className="ctaFullWidth">
    <Container>
      <Row>
        <Col>
          <h2>Intrigued?</h2>
          <Button>Schedule your personalized demo today.</Button>
        </Col>
      </Row>
    </Container>
  </section>
)

export default CTAFullWidth
