import React from "react"
import { Container, Row, Col, Card } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

import './threeboxesnobg.scss';

const ThreeBoxesNoBg = () => (
  <section className="threeBoxesNoBg">
    <Container>
      <Row>
        <Col>
          <h2>What types of services companies is Intelliva is well-suited for?</h2>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card>
            <div className="icon">
              <StaticImage 
                src="../../images/icon-agencies.png" 
                alt="Agencies" 
                title="Agencies"
                width="44"
                height="44"  
                className="iconA rounded-circle"
                loading="lazy" 
              />
            </div>
            <h3>Agencies</h3>
            <ul>
              <li>
                Advertising &amp; PR Agencies
              </li>
              <li>
                Interior Design Firms
              </li>
              <li>
                Digital Marketers<br />
                &amp; Web Designers
              </li>
              <li>
                Event Marketers &amp; Caterers
              </li>
              <li>
                Travel &amp; Hospitality Agencies
              </li>
            </ul>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <div className="icon">
              <StaticImage 
                src="../../images/icon-professional-services.png" 
                alt="Professional Services" 
                title="Professional Services"
                width="47"
                height="46" 
                className="iconPS rounded-circle" 
                loading="lazy" 
              />
            </div>
            <h3>Professional Services</h3>
            <ul>
              <li>
                Accountants &amp; CPA Firms
              </li>
              <li>
                Architecture &amp; Engineering Firms
              </li>
              <li>
                Attorneys &amp; Law Firms
              </li>
              <li>
                Home Healthcare Providers<br />
                &amp; Concierge Doctors
              </li>
              <li>
                Human Capital &amp; Staffing Agencies
              </li>
              <li>
                Mental Healthcare<br />
                Providers &amp; Therapists
              </li>
            </ul>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <div className="icon">
              <StaticImage 
                src="../../images/icon-service-providers.png" 
                alt="Service Providers" 
                title="Service Providers"
                width="52"
                height="52" 
                className="iconSP rounded-circle" 
                loading="lazy" 
              />
            </div>
            <h3>Service Providers</h3>
            <ul>
              <li>
                Consultants &amp; Consulting Firms
              </li>
              <li>
                IT, Software &amp; Managed<br />
                Service Providers
              </li>
              <li>
                Personal &amp; Virtual Assistants
              </li>
              <li>
                Personal Trainers &amp; Life Coaches
              </li>
              <li>
                Tutors &amp; Virtual Educators
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    </Container>
  </section>
)

export default ThreeBoxesNoBg
