import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

import './introhome.scss';

const IntroHome = () => (
  <section className="introHome">
    <Container>
      <Row className="align-items-center">
        <Col md={4}>
          <div className="logoWrapper">
            <StaticImage 
              src="../../images/logo-company.png" 
              alt="White Label Company" 
              title="White Label Company"
              width="175"
              height="99" 
              className="logo" 
              loading="lazy" 
            />
            <StaticImage 
              src="../../images/logo-microsoft.png" 
              alt="Microsoft Office365" 
              title="Microsoft Office365"
              width="165"
              height="101" 
              className="logo" 
              loading="lazy" 
            />
            <StaticImage 
              src="../../images/logo-google.png" 
              alt="Google Workspace" 
              title="Google Workspace"
              width="182"
              height="82" 
              className="logo" 
              loading="lazy" 
            />
          </div>
        </Col>
        <Col md={8}>
          <p>
            Bring your existing domain or use your own vanity URL for a personalized and on-brand Intelliva experience. With seamless integration among popular email services including Microsoft 365 and Google Workspace, Intelliva allows you to empower people and teams, locally and globally, with SaaS that is exclusively focused on the services sector, while continuing to use common platforms your users have grown accustomed to.
          </p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={8}>
          <p>
            Intelliva is built on a modern, secure, multi-tenant cloud platform that optimizes application performance, no matter the scale of your organization. This means that your people and teams can trust Intelliva today and tomorrow, while embracing one, two, or all 15 microservice functions in your Intelliva SaaS bundle.
          </p>
        </Col>
        <Col md={4}>
          <StaticImage 
              src="../../images/ui-screen-2.png" 
              alt="UI SCreen 2" 
              title="UI SCreen 2"
              width="487"
              height="335" 
              className="screenTwo" 
              loading="lazy" 
            />
        </Col>
      </Row>
    </Container>
  </section>
)

export default IntroHome
