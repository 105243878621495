import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ThreeBoxes from "../components/ThreeBoxes"
import IntroHome from "../components/IntroHome"
import CTAFullWidth from "../components/CTAFullWidth"
import ThreeBoxesNoBg from "../components/ThreeBoxesNoBg"

const IndexPage = () => (
  <Layout>
    <Seo 
      title="Intelliva - People, Projects, Productivity" 
      description="Intelliva - simplifying project management, CRM, and marketing orchestration for services companies worldwide."
      socialImg="../images/intelliva-logo.png" 
      link="https://www.intelliva.com/"
    />
    <ThreeBoxes />
    <IntroHome />
    <CTAFullWidth />
    <ThreeBoxesNoBg />
  </Layout>
)

export default IndexPage
